import 'react-day-picker/dist/style.css';
import { CaptionLabel, Day, MonthCaption, Nav, Weekdays, } from 'react-day-picker';
import { Chip, lighten, styled } from '@mui/material';
export const createStyles = (theme) => ({
    root: {
        position: 'relative',
        // fontFamily: theme.typography.fontFamily,
        // '--rdp-weekday-opacity': 1,
        // @ts-ignore
        '--rdp-weekday-opacity': 1,
        '--rdp-weekday-padding': '.125rem 0 1rem 0',
        '--rdp-font-family': theme.typography.fontFamily,
        // '--rdp-month_caption-font': `bold larger ${theme.typography.fontFamily}`,
        '--rdp-today-color': theme.palette.primary.dark,
        // '--rdp-day-height': '36px',
        // '--rdp-day-width': '36px',
        '--rdp-day-height': '34px',
        '--rdp-day-width': '34px',
        '--rdp-day_button-height': 'var(--rdp-day-height)',
        '--rdp-day_button-width': 'var(--rdp-day-width)',
        // '--rdp-day_button-border-radius': '.5rem',
        '--rdp-day_button-border-radius': '.375rem',
        '--rdp-selected-font': 'inherit',
        // '--rdp-selected-border': '2px solid',
        // '--rdp-day_button-border': 'none',
        '--rdp-accent-color': theme.palette.primary.dark,
        '--rdp-range_middle-background-color': theme.palette.primary.backgroundLight,
        '--rdp-outside-opacity': 1,
        '--rdp-range_middle-font': 'inherit',
        // '--rdp-day-height': '40px',
        // '--rdp-day-width': '40px',
        // '--rdp-day-height': '2.25rem',
        // '--rdp-day-width': '2.25rem',
        // '.rdp-range_start .rdp-day_button': {
        // 	backgroundColor: 'red',
        // },
    },
    weekday: {
        opacity: 1,
        // fontSize: theme.typography.body1.fontSize,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
    },
    month: {
        // Fix month and year caption relative to nav buttons.
        marginTop: '-1px',
        paddingBottom: '1px',
    },
    month_caption: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '.5rem',
    },
    months: {
        flexWrap: 'nowrap',
    },
    dropdowns: {
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.body1.fontSize,
    },
    // This commented props not working for some reason. Probably react-day-picker bug.
    // dropdown_root: {
    // 	background: 'red',
    // 	// @ts-ignore
    // 	'.rdp-caption_label button': {
    // 		color: 'red',
    // 	},
    // },
    // dropdown: {
    // 	cursor: 'pointer',
    // },
    // chevron: {
    // 	padding: '3rem',
    // },
    // week: {
    // 	width: '100%',
    // 	display: 'flex',
    // 	marginTop: '.5rem',
    // },
});
export const StyledNav = styled(Nav)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
}));
export const StyledCaptionLabel = styled(CaptionLabel)(({ theme }) => ({
    // // rdp native styles
    // zIndex: 1,
    // position: 'relative',
    // display: 'inline-flex',
    // alignItems: 'center',
    // whiteSpace: 'nowrap',
    // border: 0,
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: '1.25rem',
}));
export const StyledWeekdays = styled(Weekdays)(({ theme }) => ({
    color: theme.palette.text.muted,
    opacity: 1,
}));
/** Can't use `outside` or `day-outside` style in createStyles, since of rdp bug. */
export const StyledDay = styled(Day, { shouldForwardProp: (prop) => prop !== 'day' && prop !== 'modifiers' })(({ theme, day, modifiers }) => ({
    color: modifiers['outside'] ? theme.palette.neutral.light : undefined,
    opacity: 1,
    padding: 0,
    '&.rdp-range_middle.rdp-outside': {
        backgroundColor: lighten(theme.palette.primary.backgroundLight, 0.7),
    },
    '&.rdp-today': {
        fontWeight: theme.typography.fontWeightMedium,
    },
    ':not(.rdp-selected)': {
        '.rdp-day_button:hover': {
            backgroundColor: theme.palette.background.default,
            transitionProperty: 'color, background-color, border-color, text-decoration-color, fill, stroke',
            transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
            transitionDuration: '.15s',
        },
        '.rdp-outside&:hover': {
            color: theme.palette.text.muted,
        },
    },
    // Remove `range start` or `range end` button from selection if it is `rdp-outside`.
    ['&.rdp-range_start.rdp-outside ,&.rdp-range_end.rdp-outside']: {
        background: lighten(theme.palette.primary.backgroundLight, 0.7),
        '.rdp-day_button': {
            backgroundColor: lighten(theme.palette.primary.backgroundLight, 0.7),
            borderColor: lighten(theme.palette.primary.backgroundLight, 0.7),
            color: theme.palette.neutral.light,
        },
    },
}));
export const StyledMonthCaption = styled(MonthCaption)(({ theme }) => ({
    '.rdp-dropdown_root': {
        cursor: 'pointer',
        '.rdp-caption_label button': {
            color: theme.palette.text.disabled,
        },
        ':hover': {
            '.rdp-caption_label button': {
                color: theme.palette.primary.main,
            },
        },
        // How options looks like. Years list.
        '.rdp-years_dropdown': {
            paddingLeft: '1rem',
            width: ' 5.5rem',
        },
    },
}));
export const ShortcutsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '.75rem',
    paddingRight: '2rem',
});
export const ResetChip = styled(Chip)({
    marginTop: '1rem',
});
