import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DateRangePicker, customPeriodDefaultDateFrom, customPeriodDefaultDateTo } from 'core/ui';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { PeriodDateRangeInnerContainer } from './PeriodDateRange.styled';
import { getPeriodDateRangeValues } from './helpers';
import { t } from 'shared/translations';
import { useMemo } from 'react';
/**
 * Sets date range and triggers {@link onChange} callback.
 * @param {PeriodDateRangeValue} value - Value
 * @param {(value: PeriodDateRangeValue) => void} onChange - callback that is called on value change.
 * @param {boolean | undefined} disabled - Switch the component to disabled state.
 * @param {string | undefined} buttonClassName - adds class to buttons.
 * @param {boolean} invalid - Shows that value entered is invalid.
 */
const PeriodDateRange = ({ value, disabled, invalid, buttonClassName, onChange, ...dateRangePickerOwnProps }) => {
    // Not to be used directly.
    // Use `periodValues` instead, since `value` contains null values for range in case if named period is chosen.
    const { period, periodDateFrom, periodDateTo } = value;
    // Contains actual value.
    const periodValues = useMemo(() => period === 'custom'
        ? { periodDateFrom: periodDateFrom ?? customPeriodDefaultDateFrom, periodDateTo: periodDateTo ?? customPeriodDefaultDateTo }
        : getPeriodDateRangeValues(period), [period, periodDateFrom, periodDateTo]);
    const setCustomPeriodRange = () => {
        onChange({
            period: 'custom',
            ...periodValues,
        });
    };
    const handleDateRangePickerValueChange = (value) => {
        onChange({ period: 'custom', periodDateFrom: value?.from ?? null, periodDateTo: value?.to ?? null });
    };
    return (_jsxs(PeriodDateRangeInnerContainer, { children: [_jsxs(ToggleButtonGroup, { exclusive: true, color: "primary", size: "medium", value: period, disabled: disabled, onChange: (_, newPeriod) => {
                    if (newPeriod === null || newPeriod === period)
                        return;
                    if (newPeriod === 'custom') {
                        setCustomPeriodRange();
                        return;
                    }
                    const predefinedPeriodRangeValues = getPeriodDateRangeValues(newPeriod);
                    onChange({
                        period: newPeriod,
                        periodDateFrom: predefinedPeriodRangeValues.periodDateFrom,
                        periodDateTo: predefinedPeriodRangeValues.periodDateTo,
                    });
                }, children: [_jsx(ToggleButton, { value: "day", className: buttonClassName, children: t('day.label') }), _jsx(ToggleButton, { value: "week", className: buttonClassName, children: t('week.label') }), _jsx(ToggleButton, { value: "month", className: buttonClassName, children: t('month.label') }), _jsx(ToggleButton, { value: "year", className: buttonClassName, children: t('year.label') }), _jsx(ToggleButton, { value: "custom", className: buttonClassName, children: t('period.label').toLocaleLowerCase() })] }), _jsx(DateRangePicker, { value: { from: periodValues.periodDateFrom ?? undefined, to: periodValues.periodDateTo ?? undefined }, onChange: handleDateRangePickerValueChange, invalid: invalid, disabled: period !== 'custom' || disabled, ...dateRangePickerOwnProps })] }));
};
export { PeriodDateRange };
